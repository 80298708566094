@import "https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Round";
@import "assets/styles/reset";
@import "assets/styles/base";
@import "assets/styles/custom-components";
@import "assets/styles/theme";
@import "assets/styles/typography-custom";
@import "assets/styles/list";
@import "assets/styles/mobile";
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-quartz.css';

.ag-header-cell-filter-none .ag-icon {
  display: none;
}


